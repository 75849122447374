<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="auto">
          <h1 class="text-h1 mb-16 font-weight-bold">404</h1>

          <div class="text-h4 pt-8 pb-10">Page not found :(</div>

          <v-btn depressed large to="/"> Get me out of here! </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>


<script>
export default { name: "error404" };
</script>

<style lang="sass">
#error-view h1.text-h1
  font-size: 14rem !important
</style>
